import React, { useEffect } from 'react'
 import Cookies from 'js-cookie'
 
const LogOut =()=> {

    
const clearCookies=()=>{
	Cookies.remove('gaatpkstnm')	
 Cookies.remove('gaatpkarfsh')

	Cookies.remove('gaatpkamtk')	
 Cookies.remove('gaatpkauscd')

 Cookies.remove('_gaatpbexpt')
 Cookies.remove('_gaatpexpt')


}

 useEffect(()=>{  
  clearCookies()
  window.open("/", '_self')
},[]); 

  return (
    <div>...please wait</div>
  )
}

export default LogOut